import axios from "@axios";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getEmployees", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/getOneEmployee/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    verifyEmail(ctx, { mail }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/getVerifyEmail/${mail}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchUserLogin(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/getOneUser/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    addEmployee(ctx, employeeData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/addEmployee", employeeData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => resolve(response))
          .catch((error) => reject(error.response.data.msg));
      });
    },

    updateEmployee(ctx, queryParams) {
      const id = queryParams.get("id");
      queryParams.delete("id");

      return new Promise((resolve, reject) => {
        axios
          .put(`/updateEmployee/${id}`, queryParams, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error.response.data.msg));
      });
    },

    updateCountryStatus(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        const status = { status: queryParams.status };
        axios
          .put(`/updateEmployeeStatus/${queryParams.id}`, { params: status })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteCountry(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/deleteEmployee/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchHelpers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getHelpes", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchEmployeesServices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getEmployeeServices", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchCategoryServices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getCategoryServices", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchServices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getSercicesxCategory", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    addServiceEmployee(ctx, employeeData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/addEmployeeServices", employeeData.model.value)
          .then((response) => resolve(response))
          .catch((error) => reject(error.response.data.msg));
      });
    },

    deleteEmployeeServices(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/deleteEmployeeServices/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateEmployeeServices(ctx, queryParams) {
      const id = queryParams.id;
      delete queryParams.id;
      delete queryParams.id_employe;
      delete queryParams.id_service;
      delete queryParams.name;

      return new Promise((resolve, reject) => {
        axios
          .put(`/updateEmployeeService/${id}`, queryParams)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchOffices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getOffices", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    fetchEmployeesOffices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getEmployeeOffice", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
    addEmployeeOffice(ctx, employeeData) {
      console.log("Que paso el employeeData -->", employeeData);
      return new Promise((resolve, reject) => {
        axios
          .post("/addEmployeeOffice", employeeData.model.value)
          .then((response) => resolve(response))
          .catch((error) => reject(error.response.data.msg));
      });
    },
    deleteEmployeeOffice(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/deleteOfficeEmployee/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    fetchCommision(ctx, queryParams) {
      console.log("que tra params:", queryParams);
      return new Promise((resolve, reject) => {
        axios
          .get("/getPaymamentEmployes", { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
